.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* 
vijak自己加的
*/
.text_overflow{
  white-space: nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}
.padding_bottom_0{
  padding-bottom: 0 !important;
}
.padding_top_0{
  padding-top: 0 !important;
}
.none{
  display: none !important;
}
.margin_top_30{
  margin-top: 30px !important;
}
.margin_bottom_30{
  margin-bottom: 30px !important;
}
.slick-prev{
  color: #000 !important;
}
.slick-next{
  color: #000 !important;
}
.my_theme_color{
  color: #1572BB !important;
}
.hover:hover{
  color: #0f9eb1 !important;
}
.ant-dropdown-menu-title-content{
  text-align: center !important;
}
.color_2b2f6b{
  background: #2b2f6b !important;
  border-radius: 10px 10px 0px 0px !important;
}
.color_1572BB{
  background: #1572BB !important;
  border-radius: 10px 10px 0px 0px !important;
}
.color_76c0e3{
  background: #76c0e3 !important;
  border-radius: 10px 10px 0px 0px !important;
}
.carousel{
  display: flex !important;
  justify-content: space-around !important;
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

