/********** Template CSS **********/
:root {
  --primary: #1572BB;
  --secondary: #354F8E;
  --light: #EFF5F9;
  --dark: #1D2A4D;
}

.text-white-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.text-primary {
  color: var(--primary) !important;
}

.btn {
  font-weight: 700;
  transition: .5s;
}

.btn:hover {
  -webkit-box-shadow: 0 8px 6px -6px #555555;
  -moz-box-shadow: 0 8px 6px -6px #555555;
  box-shadow: 0 8px 6px -6px #555555;
}

.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 50% 50% 0 0;
  z-index: 99;
}

.navbar-light .navbar-nav .nav-link {
  font-family: 'Jost', sans-serif;
  position: relative;
  margin-left: 30px;
  padding: 30px 0;
  font-size: 18px;
  font-weight: 700;
  color: var(--dark);
  outline: none;
  transition: .5s;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

.bi-gold {
  color: #baa154 !important;
}

.bg-shadow {
  box-shadow: 4px 0 10px 0 rgba(99, 99, 99, .08);
}

@media (min-width: 1200px) {
  h4 .price-text {
    font-size: 1.75rem;
  }
}

@media (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    /* remove the gap so it doesn't close */
  }

  .navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 5px;
    bottom: 0;
    left: 50%;
    background: var(--primary);
    transition: .5s;
  }

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
    width: 100%;
    left: 0;
  }

  .doctor-search-drop {
    padding: 5px;
  }
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
    padding: 10px 0;
  }
}

.hero-header {
  background: url(../img/hero.jpg) top right no-repeat;
  background: url(https://gbapmda1.odoo.com/zh_TW/web/image/267-0ee6f11d/QDe9.gif) top right no-repeat;
  background-size: cover;
  /*background-size: 100% auto;*/
  background-position: center;
}

.service-item {
  position: relative;
  height: 350px;
  padding: 0 30px;
  transition: .5s;
}

.service-item .service-icon {
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border-radius: 50%;
  transform: rotate(-14deg);
}

.service-item .service-icon i {
  transform: rotate(15deg);
}

.service-item a.btn {
  position: absolute;
  width: 60px;
  bottom: -48px;
  left: 50%;
  margin-left: -30px;
  opacity: 0;
}

.service-item:hover a.btn {
  bottom: -24px;
  opacity: 1;
}

.price-carousel::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background: var(--primary);
  border-radius: 8px 8px 50% 50%;
  z-index: -1;
}

.price-carousel .owl-nav {
  margin-top: 35px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.price-carousel .owl-nav .owl-prev,
.price-carousel .owl-nav .owl-next {
  position: relative;
  margin: 0 5px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #FFFFFF;
  font-size: 22px;
  border-radius: 45px;
  transition: .5s;
}

.price-carousel .owl-nav .owl-prev:hover,
.price-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

@media (min-width: 576px) {
  .team-item .row {
    height: 350px;
  }

  .doctor-item .row {
    height: 200px;
  }

  .offer-box {
    padding: 10px !important;
  }

  .offer-box-height {
    height: 100%;
  }
}

.team-carousel .owl-nav {
  position: absolute;
  padding: 0 45px;
  width: 100%;
  height: 45px;
  top: calc(50% - 22.5px);
  left: 0;
  display: flex;
  justify-content: space-between;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 45px;
  font-size: 22px;
  transition: .5s;
}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover {
  background: var(--dark);
}

.testimonial-carousel .owl-dots {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  background: var(--light);
  border: 2px solid var(--primary);
  border-radius: 20px;
  transition: .5s;
}

.testimonial-carousel .owl-dot.active {
  width: 40px;
  height: 40px;
  background: var(--primary);
}

.testimonial-carousel .owl-item img {
  width: 150px;
  height: 150px;
}

.partner-item {
  margin: 15px auto;
  font-weight: normal;
}

/*banner css */
.banner {
  position: relative;
  height: 500px;
}

.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

.banner-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  color: #fff;
}

.banner-text h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.banner-text p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.video-box {
  height: 150px;
}

.price-text {
  color: rgb(186, 161, 84);
  margin-right: 30px;
  font-weight: 500;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.color_2b2f6b {

  background: #2b2f6b !important;

  border-radius: 10px 10px 0px 0px !important;

}

.color_1572BB {

  background: #1572BB !important;

  border-radius: 10px 10px 0px 0px !important;

}

.color_76c0e3 {

  background: #76c0e3 !important;

  border-radius: 10px 10px 0px 0px !important;

}

.image-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.image-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-modal-content {
  background-color: white;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 92svw;
  height: 92svh;
}

.image-close-button {
  position: absolute;
  top: 4px;
  right: 16px;
  font-size: 40px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  z-index: 2010;
}

.image-modal-container {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-modal-container img {
  /* width: 100%;
  height: auto; */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transform-origin: 0 0;
  cursor: move;
}